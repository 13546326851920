// @ts-strict-ignore
/* eslint-disable no-restricted-imports, @fullscript/cross-reference */
import * as remix from "@remix-run/react";
import * as router from "react-router-dom";

type SetURLSearchParams = (
  nextInit?: router.URLSearchParamsInit | ((prev: URLSearchParams) => router.URLSearchParamsInit),
  navigateOpts?: router.NavigateOptions
) => void;

/**
 * A hook wrapping useSearchParams from Remix and react-router-dom
 * It can be used in interchangeably in a Remix or react-router-dom based SPA and work as expected
 * Means that devs don't need to worry about where an app is running and what API to use
 *
 * @returns - either a Remix or react-router-dom based [URLSearchParams, setter] array
 */
const useSearchParams = () => {
  let routerSearchParams: readonly [URLSearchParams, SetURLSearchParams];
  let remixSearchParams: readonly [URLSearchParams, SetURLSearchParams];
  let routerError: Error;
  let remixError: Error;

  // Order is important here, we default to trying the react-router-dom version
  // If it does not throw, we know we are operating in some sort of nested router during the Remix migration
  // Otherwise if it fails, we know we are on a Remix only page
  try {
    routerSearchParams = router.useSearchParams();
  } catch (e) {
    routerError = e;
  }

  try {
    remixSearchParams = remix.useSearchParams();
  } catch (e) {
    if (!routerSearchParams) {
      remixError = e;
    }
  }

  if (routerError && remixError) {
    // Logging Remix error to console so that it reduces the chances we miss errors that are Remix specific
    // We can't throw the remixError or routerError separately as one or the other will always be present
    // eslint-disable-next-line no-console
    console.log(remixError);
    throw routerError;
  }

  return routerSearchParams || remixSearchParams;
};

export { useSearchParams };
